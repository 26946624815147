<template>
  <div id="playlist-search-result">
    <h4 class="heading-title" v-if="headingTitle">
      {{ headingTitle }}
    </h4>
    <Vuetable
      data-path="songs"
      pagination-path="pagination"
      ref="vuetable"
      track-by=""
      :api-url="apiUrl"
      :fields="fields"
      :http-options="httpOptions"
      :css="css.table"
      :per-page="10"
      :append-params="moreParams"
      :query-params="{
        sort    : 'sort',
        page    : 'page',
        perPage : 'perPage'
      }"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loading="showLoader"
      @vuetable:loaded="hideLoader">

      <!-- Preview -->
      <template slot="preview" slot-scope="props">
        <vue-plyr :ref="`plyr-${props.rowData.songId}`">
          <audio :id="props.rowData.songId">
            <source :src="props.rowData.url" type="audio/mp3" />
          </audio>
        </vue-plyr>
      </template>

      <!-- Action -->
      <template slot="action" slot-scope="props">
        <b-button
          variant="success"
          @click="checkSong(props.rowData)"
          :disabled="playlistSongIds.includes(props.rowData.id)"
          block>
          Add
        </b-button>
      </template>
    </Vuetable>
    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="css.pagination"
      ></vuetable-pagination>
    </div>

    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import TableStyle from '../table-bootstrap-css.js';

  export default {
    props : {
      plistId : {
        type     : Number,
        required : false,
      },
      headingTitle : {
        type     : String,
        required : false,
      },
      listType : {
        type     : String,
        required : true,
      },
      endpoint : {
        type     : String,
        required : false,
      },
      filter : {
        type     : String,
        required : false,
      },
    },
    data() {
      return {
        loading    : false,
        apiUrl     : process.env.VUE_APP_ROOT_API + this.endpoint,
        moreParams : {
          filter  : this.filter,
          plistId : parseInt(this.plistId),
        },
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name      : '__slot:action',
            dataClass : 'text-center',
          }, {
            name  : 'title',
            title : 'Title',
          }, {
            name  : 'album',
            title : 'Album',
          }, {
            name       : '__slot:preview',
            title      : 'Preview',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          }, {
            name       : 'length',
            title      : 'Length',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
        ],
      }
    },
    computed : {
      playlistSongIds() {
        return this.$store.getters['playlist/playlistSongIds'];
      },
    },
    components : {
      'AppLoaderAdmin'     : () => import('@/components/layout/AppLoaderAdmin'),
      'Vuetable'           : () => import('vuetable-2/src/components/Vuetable'),
      'VuetablePagination' :
        () => import('vuetable-2/src/components/VuetablePagination'),
      'VuetablePaginationInfo' :
        () => import('vuetable-2/src/components/VuetablePaginationInfo'),
    },

    watch : {
      plistId(val) {
        if (val) {
          this.moreParams = {
            plistId : val,
          };
          this.refreshTable();
        }
      },
      // filter(val) {
      //   console.log("filter: ", val);
      //   if (val) {
      //     this.moreParams = {
      //       filter : val,

      //       // plistId : null,
      //     };
      //     console.log("More Params: ", JSON.stringify(this.moreParams));
      //     this.refreshTable();
      //   }
      // },
    },
    methods : {

      /**
       * Table Data
       * @param data
       * @return {object}
       */
      transform(data) {
        const transformed = {};
        transformed.songs = [];
        let res = null;

        if (this.endpoint === 'api/search/song')
          res = data.data;

        if (this.endpoint === 'api/playlist/songs')
          res = data.result;

        transformed.pagination = {
          'total'         : res.total,
          'per_page'      : res.perPage,
          'current_page'  : res.currentPage,
          'last_page'     : res.lastPage,
          'next_page_url' : res.nextPageUrl,
          'prev_page_url' : res.prevPageUrl,
          'from'          : res.from,
          'to'            : res.to,
        };

        for (let i = 0; i < res.data.length; i++) {
          transformed.songs.push({
            id     : res.data[i].mobile_music_id,
            title  : res.data[i].music_title,
            album  : res.data[i].album_name,
            length : this.formatTime(res.data[i].length),
            url    : res.data[i].url,
          });
        }

        if (res.total > 0)
          this.updatePlayer();

        return transformed;
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$nextTick(() => {
          this.$refs.pagination.setPaginationData(paginationData);
          this.$refs.paginationInfo.setPaginationData(paginationData);
        });
      },

      /**
       * Update Vuetable page
       * @param page
       */
      onChangePage(page) {
        this.$nextTick(() => {
          this.$refs.vuetable.changePage(page);
        });
      },

      /**
       * Check if Song is already added
       * @param data
       */
      checkSong(data) {
        const isAdded = this.playlistSongIds.includes(data.id);

        if (!isAdded)
          this.addToPlaylist(data.id);
        else {
          this.$modal.show('dialog', {
            title   : 'Duplicate Song',
            text    : `<b>${data.title}</b> is already in your playlist`,
            buttons : [{
              title : 'Skip Duplicate',
            }],
          });
        }
      },

      /**
       * Add Song to Playlist
       * @param musicId
       */
      addToPlaylist(musicId) {
        this.$http.post('api/playlist/song', {
          mPlaylistId : this.$route.params.playlistId,
          musicId     : musicId,
        }).then(() => {
          this.$store.dispatch(
            'playlist/getPlaylistSongIds',
            this.$route.params.playlistId);
          this.$emit('song-added');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Added song successfully!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Format Song Length
       * @param value
       */
      formatTime(value) {
        let result = '';
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value - (hours * 3600)) / 60);
        let seconds = value - (hours * 3600) - (minutes * 60);

        // round seconds & exclude remainder
        seconds = parseInt(Math.round(seconds * 100) / 100);

        if (hours > 0) {
          result = (hours < 10 ? '0' + hours : hours) +
            ':' + (minutes < 10 ? '0' + minutes : minutes) +
            ':' + (seconds < 10 ? '0' + seconds : seconds);
        } else {
          result = (minutes < 10 ? '0' + minutes : minutes) + ':' +
            (seconds < 10 ? '0' + seconds : seconds);
        }

        return result;
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * Hide Loader
       */
      hideLoader() {
        this.loading = false;
      },

      /**
       * Refresh Table
       */
      refreshTable() {
        this.$nextTick(() => {
          this.$refs.vuetable.refresh();
        });
      },

      /**
       * Update audio player source
       */
      updatePlayer() {
        const audios = document.getElementsByTagName('audio');
        if (audios.length > 0) {
          for (let i = 0; i < audios.length; i++)
            audios[i].load();
        }
      },
    },
    mounted() {
      /**
       * Audio Player Listener
       */
      document.addEventListener('playing', e => {
        const playingAudio = e.target.getElementsByTagName('audio')[0];
        const audios = document.getElementsByTagName('audio');

        if (audios.length > 0) {
          for (let i = 0; i < audios.length; i++) {
            if (audios[i] !== playingAudio)
              audios[i].pause();
          }
        }
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";

  .heading-title {
    letter-spacing: 0;
  }
</style>